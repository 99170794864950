import React from 'react';
import { WidgetConfiguration } from '@sg-widgets/shared-core';
import { IWidgetConfigurationContext, WidgetConfigurationContext } from '../../../common/configuration';
import { ITranslatorProps } from '../../../common/sgwt-i18n';
import { checkResponseStatus, isProductionEnvironment } from '../../../common/sgwt-widgets-utils';
import { ACCESSIBILITY_STATEMENT_LINK, AccessibilityStatementDto } from '../shared/mini-footer.endpoint';

interface IAccessibilityLinkProps extends ITranslatorProps {
  accessibilityCompliance?: string;
  accessibilityLink?: string;
  accessibilityUid?: string;
  onAccessibilityClicked: () => void;
}

interface IAccessibilityLinkState {
  complianceLevel: Level | null;
}

type Level = 'none' | 'partial' | 'full';
const allowedLevels = ['none', 'partial', 'full'];

export const getAccessibilityCompliance = async (
  widgetConfiguration: WidgetConfiguration,
  uid: string,
): Promise<AccessibilityStatementDto> => {
  const prodEnv = isProductionEnvironment(widgetConfiguration);
  const url = ACCESSIBILITY_STATEMENT_LINK[prodEnv ? 'production' : 'homologation'].replace('{uid}', uid);
  return fetch(url)
    .then(checkResponseStatus)
    .then((data) => data.json());
};

export class AccessibilityLink extends React.Component<IAccessibilityLinkProps, IAccessibilityLinkState> {
  static readonly contextType = WidgetConfigurationContext;
  private widgetConfiguration: WidgetConfiguration;

  constructor(props: IAccessibilityLinkProps, context: IWidgetConfigurationContext) {
    super(props);
    this.widgetConfiguration = context!.widgetConfiguration;
    const { accessibilityCompliance, accessibilityUid } = props;
    let complianceLevel: Level | null = null;
    if (
      !accessibilityUid &&
      accessibilityCompliance &&
      allowedLevels.indexOf(accessibilityCompliance.toLowerCase()) !== -1
    ) {
      complianceLevel = accessibilityCompliance.toLowerCase() as Level;
    }
    this.state = {
      complianceLevel,
    };
  }

  componentDidMount() {
    const { accessibilityUid } = this.props;
    if (accessibilityUid) {
      getAccessibilityCompliance(this.widgetConfiguration, accessibilityUid).then((data: AccessibilityStatementDto) => {
        const { criteriaCompliance } = data;
        this.setState({
          complianceLevel: criteriaCompliance < 50 ? 'none' : criteriaCompliance < 100 ? 'partial' : 'full',
        });
      });
    }
  }

  render() {
    const { accessibilityLink } = this.props;
    const { complianceLevel } = this.state;

    if (!complianceLevel) {
      return null;
    }

    const label = this.props.translator.translate(`accessibility.${complianceLevel}`);
    return (
      <li>
        {accessibilityLink ? (
          <a
            onClick={this.props.onAccessibilityClicked}
            href={accessibilityLink}
            target="_blank"
            rel="noopener noreferrer"
            className="h6 font-weight-normal fw-normal sgbs-footer-item"
          >
            {label}
          </a>
        ) : (
          <span className="h6 font-weight-normal fw-normal sgbs-footer-item">{label}</span>
        )}
      </li>
    );
  }
}
