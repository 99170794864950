export interface AccessibilityStatementDto {
  uid: string;
  url: string;
  name: string;
  averageScore: number;
  criteriaCompliance: number;
}

export const ACCESSIBILITY_STATEMENT_LINK: Record<'homologation' | 'production', string> = {
  homologation: 'https://sgwt-shared-dev.sgmarkets.com/accessibility-statements/statements/{uid}.json',
  production: 'https://sgwt-cdn-shared.sgmarkets.com/accessibility-statements/statements/{uid}.json',
};

export const getAccessibilityStatementPageUrl = (production: boolean, language: 'en' | 'fr', uid?: string) => {
  const domain = production ? 'shared.sgmarkets.com' : 'shared-uat.sgmarkets.com';
  if (uid) {
    return `https://${domain}/accessibility/statement-${language}.html?uid=${uid}`;
  }
  const url = window.location.protocol + '//' + window.location.host;
  return `https://${domain}/accessibility/statement/${language}.html?redirect_uri=${url}`;
};
